
import { defineComponent, onMounted, computed, ref } from "vue";
import DiagnosticsService from "../services/diagnosticsService";
import { Details } from "../models/diagnostics/diagnostics";
import DiagnosticsModule from "../store/modules/Diagnostics/diagnostics";

export default defineComponent({
  setup () {
    const loading = ref(true);
    const statusCode = ref(400);
    const statusCodeResult = ref(null);
    const generateExceptionResult = ref(null);
    const invalidUrlResult = ref(null);

    const details = computed((): Details => {
      return DiagnosticsModule.getDetails;
    });

    const userAgent = computed(() => {
      return navigator.userAgent;
    });

    const initializePage = () => {
      DiagnosticsService.getDetails().then(res => {
        DiagnosticsModule.setDetails(res as Details);
        loading.value = false;
      });
    };

    const postStatusCode = () => {
      DiagnosticsService.postStatusCode(statusCode.value).then((r) => {
        statusCodeResult.value = r;
      });
    };

    const generateException = () => {
      DiagnosticsService.generateException().then((r) => {
        generateExceptionResult.value = r;
      });
    };

    const goToInvalidUrl = () => {
      DiagnosticsService.goToInvalidUrl().then((r) => {
        invalidUrlResult.value = r;
      });
    };

    onMounted(() => {
      initializePage();
    });

    return { loading, details, userAgent, invalidUrlResult, generateExceptionResult, statusCode, statusCodeResult, postStatusCode, generateException, goToInvalidUrl };
  },
});
